import { createSlice } from "@reduxjs/toolkit";

const airtimeConverterSlice = createSlice({
  name: "airtimeConverter",
  initialState: {
    isFetching: false,
    error: false,
    message: false,
    airtimeCovList: [],
    airtimeConList: [],
    tvConList: [],
    electricityConList: [],
    dataConList: [],
    dataPinList: [],
    creditUser: null,
  },
  reducers: {
    getAirtimeCovStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAirtimeCovSucess: (state, action) => {
      state.isFetching = false;
      state.airtimeCovList = action.payload;
    },
    getAirtimeCovFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    getAirtimeConStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAirtimeConSucess: (state, action) => {
      state.isFetching = false;
      state.airtimeConList = action.payload;
    },
    getAirtimeConFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    getDataPinStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getDataPinSucess: (state, action) => {
      state.isFetching = false;
      state.dataPinList = action.payload;
    },
    getDataPinFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    getTvConStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getTvConSucess: (state, action) => {
      state.isFetching = false;
      state.tvConList = action.payload;
    },
    getTvConFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    getElectricityConStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getElectricityConSucess: (state, action) => {
      state.isFetching = false;
      state.electricityConList = action.payload;
    },
    getElectricityConFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    modifyAirtimeStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.message = false;
    },
    getDataConStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getDataConSucess: (state, action) => {
      state.isFetching = false;
      state.dataConList = action.payload;
    },
    getDataConFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    modifyDataStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.message = false;
    },
    modifyDataSucess: (state, action) => {
      state.isFetching = false;
      const { id, data } = action.payload;

      if (Array.isArray(state.dataConList)) {
        const index = state.dataConList.findIndex((item) => item.id === id);
        if (index !== -1) {
          state.dataConList[index] = data;
        } else {
          console.warn(`Item with id ${id} not found in dataConList`);
        }
      } else {
        console.error("dataConList is not an array", state.dataConList);
      }

      state.message = true;
      state.error = false;
    },

    modifyDataFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    modifyDataPinStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.message = false;
    },
    modifyDataPinSucess: (state, action) => {
      state.isFetching = false;
      const { id, data } = action.payload;
      const index = state.dataPinList.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.dataPinList[index] = data;
      }
      state.message = true;
      state.error = false;
    },
    modifyDataPinFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    modifyAirtimeSucess: (state, action) => {
      state.isFetching = false;
      const { id, airtime } = action.payload;
      const index = state.airtimeConList.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.airtimeConList[index] = airtime;
      }
      state.message = true;
      state.error = false;
    },
    modifyAirtimeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    modifyTvStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.message = false;
    },
    modifyTvSucess: (state, action) => {
      state.isFetching = false;
      const { id, tv } = action.payload;
      const index = state.tvConList.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.tvConList[index] = tv;
      }
      state.message = true;
      state.error = false;
    },
    modifyTvFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    modifyElectricityStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.message = false;
    },
    modifyElectricitySucess: (state, action) => {
      state.isFetching = false;
      const { id, electricity } = action.payload;
      const index = state.tvConList.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.electricityConList[index] = electricity;
      }
      state.message = true;
      state.error = false;
    },
    modifyElectricityFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    creditStart: (state) => {
      state.isFetching = true;
    },
    creditSuccess: (state, action) => {
      state.isFetching = false;
      state.creditUser = action.payload;
      state.message = true;
      state.error = false;
    },
    creditFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.message = false;
    },
  },
});

export const {
  getAirtimeCovStart,
  getAirtimeCovSucess,
  getAirtimeCovFailure,
  getAirtimeConStart,
  getAirtimeConSucess,
  getAirtimeConFailure,
  getDataPinStart,
  getDataPinSucess,
  getDataPinFailure,
  getTvConStart,
  getTvConSucess,
  getTvConFailure,
  getElectricityConStart,
  getElectricityConSucess,
  getElectricityConFailure,
  getDataConStart,
  getDataConSucess,
  getDataConFailure,
  getAirtimeResellerStart,
  getAirtimeResellerSucess,
  getAirtimeResellerFailure,
  creditStart,
  creditSuccess,
  creditFailure,
  modifyAirtimeStart,
  modifyAirtimeSucess,
  modifyAirtimeFailure,
  modifyTvStart,
  modifyTvSucess,
  modifyTvFailure,
  modifyElectricityStart,
  modifyElectricitySucess,
  modifyElectricityFailure,
  modifyDataStart,
  modifyDataSucess,
  modifyDataFailure,
  modifyDataPinStart,
  modifyDataPinSucess,
  modifyDataPinFailure,
} = airtimeConverterSlice.actions;
export default airtimeConverterSlice.reducer;
