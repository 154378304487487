import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { modifyTvCon } from "../../Redux/apiCalls";
import { H2, MsgContainer } from "../transaction/pending.styles";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import {
  Btn,
  Container,
  Form,
  FormWrapper,
  Input,
  InputContainer,
  Wrapper,
} from "../Wallet/credit.styles";

const ModifyResellerTv
 = () => {
  const location = useLocation();
  const Id = Number(location.pathname.split("/")[3]);
  const tvModify = useSelector((state) =>
    state.airtimeConverter.tvConList.find((tv) => tv.id === Id)
  );
  const { isFetching, error } = useSelector((state) => state.airtimeConverter);

  console.log(tvModify);

  const [inputName, setInputName] = useState(
    tvModify.name
  );
  const [inputDiscountData, setInputDiscountData] = useState(
    tvModify.discount
  );
  const [inputPrice, setInputPrice] = useState(tvModify.price);
  const [isMessageVisible, setMessageVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setMessageVisible(true);
      const timer = setTimeout(() => {
        setMessageVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputNetworkChange = (event) => {
    setInputName(event.target.value);
  };

 

  const handleInputDiscountChange = (event) => {
    setInputDiscountData(event.target.value);
  };

  const handleInputPriceChange = (event) => {
    setInputPrice(event.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    modifyTvCon(dispatch, {
      id: tvModify.id,
      price: inputPrice,
      discount: inputDiscountData,
      name: inputName,
    });
    setTimeout(() => navigate("/reseller/tvcontrol"), 1000);
  };

  const handleCloseMessage = () => {
    setMessageVisible(false);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Modify Airtime Network</H3>
            <DescP>
              Reseller / <DescSpan>Modify Airtime Network</DescSpan>
            </DescP>
          </Desc>
          <FormWrapper onSubmit={handleFormSubmit}>
            <Form>
              <InputContainer>
                <p
                  style={{ padding: "5px", fontSize: "16px", color: "#495057" }}
                >
                  Name
                </p>
                <Input
                  type="text"
                  onChange={handleInputNetworkChange}
                  value={inputName}
                  name="name"
                  readOnly
                />
              </InputContainer>

              <InputContainer>
                <p
                  style={{ padding: "5px", fontSize: "16px", color: "#495057" }}
                >
                  Price
                </p>
                <Input
                  type="text"
                  onChange={handleInputPriceChange}
                  value={inputPrice}
                  name="name"
                />
              </InputContainer>

              <InputContainer>
                <p
                  style={{ padding: "5px", fontSize: "16px", color: "#495057" }}
                >
                  Discount
                </p>
                <Input
                  type="text"
                  onChange={handleInputDiscountChange}
                  value={inputDiscountData}
                  name="name"
                />
              </InputContainer>

             
              
            </Form>
            <Btn type="submit">{isFetching ? "Updating" : "Update"}</Btn>
            {isMessageVisible && error && (
              <MsgContainer>
                <H2>opps!! something went wrong</H2>
                <Close
                  onClick={handleCloseMessage}
                  style={{ color: "#806e6b", cursor: "pointer" }}
                />
              </MsgContainer>
            )}
          </FormWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default ModifyResellerTv
;
