import styled from "styled-components";
import { laptop, mobile, tablet } from "../../responsive";
import Card from "../card/Card";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getdashboard } from "../../Redux/apiCalls";
import { Loading } from "../../pages/transaction/pending.styles";
import { CircularProgress } from "@mui/material";

const Container = styled.div`
  margin: 70px 0;
  padding: 20px;
  flex: 5;
  ${tablet({ flex: "3" })};
  ${laptop({ marginLeft: "250px" })};
`;
const Wrapper = styled.div``;
const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 50px;
  ${mobile({ flexDirection: "column" })};
`;
const Desc = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const H3 = styled.h3`
  font-size: 18px;
  color: #4a4b4c;
`;
const DescP = styled.p`
  font-size: 14px;
  color: #8c9ea9;
`;
const DescSpan = styled.span`
  font-size: 14px;
  color: #6c757d;
`;

const Widgets = () => {
const {dashboard, isFetching} = useSelector((state) => state.dashboard)

const dispatch = useDispatch();

useEffect(() => {
  getdashboard(dispatch);
}, [dispatch]);

console.log(dashboard, "dashb");

if (isFetching) {
  return (
    <Loading>
      <CircularProgress style={{ color: "blue" }} />
    </Loading>
  );
}

  return (
    <Container>
      <Wrapper>
        <Desc>
          <H3>Home</H3>
          <DescP>
            Dashboard / <DescSpan>Home</DescSpan>
          </DescP>
        </Desc>
        <CardContainer>
          <Card type="successful" stats={dashboard}/>
          <Card type="pending" stats={dashboard}/>
        </CardContainer>
        <CardContainer>
          <Card type="today" stats={dashboard}/>
          <Card type="month" stats={dashboard}/>
        </CardContainer>
      </Wrapper>
    </Container>
  );
};

export default Widgets;
