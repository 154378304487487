import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isFetching: false,
    error: false,
    dashboard: [],
    message: null,
  },
  reducers: {
    getDashboardStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getDashboardSucess: (state, action) => {
      state.isFetching = false;
      state.dashboard = action.payload;
    },
    getDashboardFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    
  },
});

export const {
  getDashboardStart,
  getDashboardSucess,
  getDashboardFailure,
  
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
