/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { getDataPinList } from "../../Redux/apiCalls";
import { Loading } from "../transaction/pending.styles";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import {
  BtnConatiner,
  BtnDisable,
  BtnEnable,
  Container,
  Details,
  P,
  Span,
  TableWrapper,
  Wrapper,
} from "./airtimeControl.styles";
import { formatAmount } from "../../utills/formatNumber";
import { userRequest } from "../../requestMethods";
import { toast, ToastContainer } from "react-toastify";

const DataPins = () => {
  const { dataPinList, isFetching } = useSelector(
    (state) => state.airtimeConverter
  );
  const [loading, setLoading] = useState(new Set());

  const dispatch = useDispatch();

  const fetchDataPinList = () => {
    getDataPinList(dispatch);
  };

  useEffect(() => {
    fetchDataPinList();
  }, []);

  console.log(dataPinList);

  const handleButtonClick = async (id) => {
    setLoading((prev) => new Set(prev).add(id));

    try {
      const res = await userRequest.get(`/resellerDataPinConfigList/ED/${id}`);
      console.log(res);

      if (res?.data?.success === 1) {
        toast.success(res?.data?.message);
        fetchDataPinList();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  if (isFetching) {
    return (
      <Loading>
        <CircularProgress style={{ color: "blue" }} />
      </Loading>
    );
  }

  return (
    <>
      <ToastContainer />
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Reseller DataPins Control</H3>
            <DescP>
              Services / <DescSpan>Reseller DataPins Control</DescSpan>
            </DescP>
          </Desc>
          <TableWrapper>
            <P>Network List</P>
            <Details>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        id
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Network
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Product Name
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "700" }}
                      >
                        Provider Price
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "700" }}
                      >
                        Your Price
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Server
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Date Modified
                      </TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >Enable/Disable</TableCell>
                      <TableCell
                        style={{ color: "#8281cc", fontWeight: "bold" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataPinList && dataPinList.length === 0 ? (
                      <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                        <TableCell
                          colSpan={7}
                          style={{ textAlign: "center", color: "#8887a9" }}
                        >
                          No data in the table
                        </TableCell>
                      </TableRow>
                    ) : (
                      dataPinList &&
                      dataPinList.map((row) => (
                        <TableRow
                          key={row.id}
                          style={{
                            backgroundColor:
                              row.id % 2 === 0 ? "#ffffff" : "#f3f2f7",
                          }}
                        >
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.id}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.network.toUpperCase()}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.name}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            ₦{formatAmount(row.amount)}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            ₦{formatAmount(row.price)}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.server}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            <Span
                              style={{
                                backgroundColor: `${
                                  row.status === 1 ? "#5dd099" : "#f8c955"
                                }`,
                              }}
                            >
                              {row.status === 1 ? "Active" : "Inactive"}
                            </Span>
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.updated_at}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.status === 1 ? (
                              <BtnDisable
                                onClick={() => handleButtonClick(row?.id)}
                              >
                                {loading.has(row.id) ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  "Disable"
                                )}
                              </BtnDisable>
                            ) : (
                              <BtnEnable
                                onClick={() => handleButtonClick(row.id)}
                              >
                                {loading.has(row.id) ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  "Enable"
                                )}
                              </BtnEnable>
                            )}
                          </TableCell>

                          <TableCell style={{ color: "#8887a9" }}>
                            <BtnConatiner to={`/reseller/datapin/${row.id}`}>
                              Modify
                            </BtnConatiner>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Details>
          </TableWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default DataPins;
