import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { getWalletData } from "../../Redux/apiCalls";
import { Loading } from "../transaction/pending.styles";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import {
  Container,
  Details,
  Span,
  TableWrapper,
  Title,
  Wrapper,
} from "./wallet.styles";
import {
  PageNotification,
  PaginateContainer,
  PagWrapper,
} from "../Users/agent.styles";
import { formatAmount } from "../../utills/formatNumber";

const WalletList = () => {
  const { walletData, isFetching } = useSelector((state) => state.wallet);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(walletData?.last_page);
  const [currentItems, setCurrentItems] = useState(walletData?.data);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const itemsPerPage = walletData?.per_page;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      walletData?.data && walletData?.data.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(walletData?.total / itemsPerPage));
  }, [itemOffset, walletData, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % walletData?.data.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    getWalletData(dispatch, currentPage);
  }, [currentPage, dispatch]);

  if (isFetching) {
    return (
      <Loading>
        <CircularProgress style={{ color: "blue" }} />
      </Loading>
    );
  }

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Wallet List</H3>
            <DescP>
              Wallet / <DescSpan>Wallet List</DescSpan>
            </DescP>
          </Desc>
          <TableWrapper>
            <Title>Wallet Table</Title>
            <Details>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        id
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Username
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Medium
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Reference
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        O.Wallet
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        N.Wallet
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Version
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  {currentItems &&
                    currentItems.map((row) => (
                      <TableBody>
                        <TableRow
                          key={row.id}
                          style={{ backgroundColor: "#f3f2f7" }}
                        >
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.id}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.user_name}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            ₦{formatAmount(row.amount)}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            <Span
                              style={{
                                backgroundColor: `${
                                  row.status === "completed"
                                    ? "#5dd099"
                                    : "#f8c955"
                                }`,
                              }}
                            >
                              {row.status}
                            </Span>
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.medium}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.ref}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            ₦{formatAmount(row.o_wallet)}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            ₦{formatAmount(row.n_wallet)}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.version}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
              <PagWrapper>
                <PageNotification>
                  Showing {walletData?.from || "0"} to {walletData?.to || "0"}{" "}
                  of {walletData?.total || "0"} entries
                </PageNotification>
                <PaginateContainer
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName="pageNum"
                  previousLinkClassName="pageNum"
                  nextLinkClassName="pageNum"
                  forcePage={currentPage - 1}
                />
              </PagWrapper>
            </Details>
          </TableWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default WalletList;
