import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { getLoginAttempts } from "../../Redux/apiCalls";
import { laptop } from "../../responsive";
import { Loading } from "../transaction/pending.styles";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import {
  PageNotification,
  PaginateContainer,
  PagWrapper,
} from "../Users/agent.styles";

const Container = styled.div`
  margin: 70px 0;
  ${laptop({ marginLeft: "250px" })};
`;
const Wrapper = styled.div`
  padding: 20px;
`;
const TableWrapper = styled.div`
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 2px 4px 10px 1pxrgba (235, 180, 180, 0.47);
  padding: 20px 30px;
`;
const Details = styled.div`
  margin: 30px 0;
`;
const Span = styled.span`
  color: #fff;
  padding: 2.6px 4.2px;
  font-size: 10.5px;
  border-radius: 5px;
`;
const Title = styled.span`
  font-size: 20px;
  color: #4a4b4c;
  margin-bottom: 15px;
  font-weight: bold;
`;

const LoginAttempts = () => {
  const { loginAttempt, isFetching } = useSelector((state) => state.user);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(loginAttempt?.last_page);
  const [currentItems, setCurrentItems] = useState(loginAttempt?.data);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  console.log(loginAttempt);

  const itemsPerPage = loginAttempt?.per_page;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      loginAttempt?.data && loginAttempt?.data.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(loginAttempt?.total / itemsPerPage));
  }, [itemOffset, loginAttempt, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % loginAttempt?.data.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    getLoginAttempts(dispatch, currentPage);
  }, [currentPage, dispatch]);

  console.log(loginAttempt);

  if (isFetching) {
    return (
      <Loading>
        <CircularProgress style={{ color: "blue" }} />
      </Loading>
    );
  }
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Login Attempts</H3>
            <DescP>
              User / <DescSpan>Login Attempts</DescSpan>
            </DescP>
          </Desc>
          <TableWrapper>
            <Title>Login Attempts</Title>
            <Details>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        id
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Username
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Password
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        IP Address
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        City
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Region
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Country
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Provider
                      </TableCell>

                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Date
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  {currentItems &&
                    currentItems.map((row) => (
                      <TableBody>
                        <TableRow
                          key={row?.id}
                          style={{ backgroundColor: "#f3f2f7" }}
                        >
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.id}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.user_name}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.password}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row?.ip_address}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            <Span
                              style={{
                                backgroundColor: `${
                                  row.status === "authorized"
                                    ? "#5dd099"
                                    : row.status === "unauthorized"
                                    ? "#f96e5b"
                                    : "#33cdff"
                                }`,
                              }}
                            >
                              {row?.status}
                            </Span>
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.city}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.region}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.country}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.provider}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.created_at}
                          </TableCell>
                          {/* <TableCell style={{ color: "#8887a9" }}>
                            {row.status === 0 && (
                              <BtnConatiner>
                                <Button
                                  bg="approve"
                                  onClick={() =>
                                    handleAction(row.id, "approve")
                                  }
                                  disabled={loadingRowId === row.id}
                                >
                                  {loadingRowId === row.id ? (
                                    <CircularProgress
                                      size={24}
                                      style={{ color: "white" }}
                                    />
                                  ) : (
                                    "Approve"
                                  )}
                                </Button>
                                <Button
                                  onClick={() => handleAction(row.id, "reject")}
                                  disabled={loadingRowId === row.id}
                                >
                                  {loadingRowId === row.id ? (
                                    <CircularProgress
                                      size={24}
                                      style={{ color: "white" }}
                                    />
                                  ) : (
                                    "Reject"
                                  )}
                                </Button>
                              </BtnConatiner>
                            )}
                          </TableCell> */}
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
              <PagWrapper>
                <PageNotification>
                  Showing {loginAttempt?.from || "0"} to{" "}
                  {loginAttempt?.to || "0"} of {loginAttempt?.total || "0"}{" "}
                  entries
                </PageNotification>
                <PaginateContainer
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName="pageNum"
                  previousLinkClassName="pageNum"
                  nextLinkClassName="pageNum"
                  forcePage={currentPage - 1}
                />
              </PagWrapper>
            </Details>
          </TableWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default LoginAttempts;
