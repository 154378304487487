import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";


export default function QueryProviders({ children }) {
  const queryclient = new QueryClient({
    queryCache: new QueryCache({
      onError: (err) => {
        console.log(err, "err");

        if (
          err.message === "invalid signature" ||
          err.message === "Unauthenticated." ||
          err.message === "jwt expired"
        ) {
        }
      },
    }),
  });

  return (
    <>
      <QueryClientProvider client={queryclient}>{children}</QueryClientProvider>
    </>
  );
}
