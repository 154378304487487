import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import {  modifyDataPin } from "../../Redux/apiCalls";
import { H2, MsgContainer } from "../transaction/pending.styles";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import {
  Btn,
  Container,
  Form,
  FormWrapper,
  Input,
  InputContainer,
  Wrapper,
} from "../Wallet/credit.styles";
import { toast, ToastContainer } from "react-toastify";

const ModifyDataPin = () => {
  const location = useLocation();
  const Id = Number(location.pathname.split("/")[3]);
  const dataPinModify = useSelector((state) =>
    state.airtimeConverter.dataPinList.find((data) => data.id === Id)
  );
  const { isFetching, error } = useSelector((state) => state.airtimeConverter);

  console.log(dataPinModify);

  const [inputNetworkData, setInputNetworkData] = useState(
    dataPinModify?.network?.toUpperCase()
  );
  const [inputValueData, setInputValueData] = useState(dataPinModify.price);
  const [inputName, setInputName] = useState(dataPinModify.name);
  const [isMessageVisible, setMessageVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setMessageVisible(true);
      const timer = setTimeout(() => {
        setMessageVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputNetworkChange = (event) => {
    setInputNetworkData(event.target.value);
  };

  const handleInputAmountChange = (event) => {
    setInputValueData(event.target.value);
  };

  const handleInputNameChange = (event) => {
    setInputName(event.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    modifyDataPin(dispatch, {
      id: dataPinModify.id,
      name: inputName,
      price: inputValueData,
      status: 1
    });
    toast.success(`${inputName} has been updated successfully`);
    setTimeout(() => navigate("/reseller/datapin"), 3000);
  };

  const handleCloseMessage = () => {
    setMessageVisible(false);
  };

  return (
    <>
    <ToastContainer />
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Modify DataPin Network</H3>
            <DescP>
              Reseller / <DescSpan>Modify DataPin Network</DescSpan>
            </DescP>
          </Desc>
          <FormWrapper onSubmit={handleFormSubmit}>
            <Form>
              <InputContainer>
                <p
                  style={{ padding: "5px", fontSize: "16px", color: "#495057" }}
                >
                  Network
                </p>
                <Input
                  type="text"
                  onChange={handleInputNetworkChange}
                  value={inputNetworkData}
                  name="name"
                  readOnly
                />
              </InputContainer>

              <InputContainer>
                <p
                  style={{ padding: "5px", fontSize: "16px", color: "#495057" }}
                >
                  Name
                </p>
                <Input
                  type="text"
                  onChange={handleInputNameChange}
                  value={inputName}
                  name="name"
                />
              </InputContainer>
              <InputContainer>
                <p
                  style={{
                    padding: "5px",
                    fontSize: "16px",
                    color: "#495057",
                    whiteSpace: "nowrap",
                  }}
                >
                  Provider Price
                </p>
                <Input
                  type="number"
                  onChange={handleInputAmountChange}
                  value={inputValueData}
                  name="name"
                />
              </InputContainer>
            </Form>
            <Btn type="submit">{isFetching ? "Updating" : "Update"}</Btn>
            {isMessageVisible && error && (
              <MsgContainer>
                <H2>opps!! something went wrong</H2>
                <Close
                  onClick={handleCloseMessage}
                  style={{ color: "#806e6b", cursor: "pointer" }}
                />
              </MsgContainer>
            )}
          </FormWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default ModifyDataPin;
