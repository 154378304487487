import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { modifyAirtimeCon, modifyElectricityCon } from "../../Redux/apiCalls";
import { H2, MsgContainer } from "../transaction/pending.styles";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import {
  Btn,
  Container,
  Form,
  FormWrapper,
  Input,
  InputContainer,
  Option,
  Select,
  Wrapper,
} from "../Wallet/credit.styles";

const ModifyElectricity = () => {
  const location = useLocation();
  const Id = Number(location.pathname.split("/")[3]);
  const electricityModify = useSelector((state) =>
    state.airtimeConverter.electricityConList.find((electricity) => electricity.id === Id)
  );
  const { isFetching, error } = useSelector((state) => state.airtimeConverter);

  console.log(electricityModify);

  const [inputNetworkData, setInputNetworkData] = useState(
    electricityModify.name.toUpperCase()
  );
  const [inputValueData, setInputValueData] = useState(electricityModify);
  const [inputDiscountData, setInputDiscountData] = useState(
    electricityModify.discount
  );
  const [inputServerData, setInputServerData] = useState(electricityModify.server);
  const [isMessageVisible, setMessageVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setMessageVisible(true);
      const timer = setTimeout(() => {
        setMessageVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputNetworkChange = (event) => {
    setInputNetworkData(event.target.value);
  };

  const handleInputValueChange = (event) => {
    setInputValueData(event.target.value);
  };

  const handleInputDiscountChange = (event) => {
    setInputDiscountData(event.target.value);
  };

  const handleInputServerChange = (event) => {
    setInputServerData(event.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    modifyElectricityCon(dispatch, {
      id: electricityModify.id,
      discount: inputDiscountData,
    });
    // toast.success(`${inputNameData} has been updated successfully`);
    setTimeout(() => navigate("/reseller/electricitycontrol"), 1000);
  };

  const handleCloseMessage = () => {
    setMessageVisible(false);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Modify Electricity Network</H3>
            <DescP>
              Reseller / <DescSpan>Modify Electricity Network</DescSpan>
            </DescP>
          </Desc>
          <FormWrapper onSubmit={handleFormSubmit}>
            <Form>
              <InputContainer>
                <p
                  style={{ padding: "5px", fontSize: "16px", color: "#495057" }}
                >
                  Network
                </p>
                <Input
                  type="text"
                  onChange={handleInputNetworkChange}
                  value={inputNetworkData}
                  name="name"
                  readOnly
                />
              </InputContainer>

              <InputContainer>
                <p
                  style={{ padding: "5px", fontSize: "16px", color: "#495057" }}
                >
                  Discount
                </p>
                <Input
                  type="text"
                  onChange={handleInputDiscountChange}
                  value={inputDiscountData}
                  name="name"
                />
              </InputContainer>
              {/* <InputContainer>
                <Select
                  name="fundType"
                  id=""
                  value={inputValueData}
                  onChange={handleInputValueChange}
                >
                  <Option value="fund">Deactivate</Option>
                  <Option value="debit">Activate</Option>
                </Select>
              </InputContainer> */}
              <InputContainer>
                <Select
                  name="server"
                  id=""
                  value={inputServerData}
                  onChange={handleInputServerChange}
                >
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                  <Option value="7">7</Option>
                  <Option value="8">8</Option>
                </Select>
              </InputContainer>
            </Form>
            <Btn type="submit">{isFetching ? "Updating" : "Update"}</Btn>
            {isMessageVisible && error && (
              <MsgContainer>
                <H2>opps!! something went wrong</H2>
                <Close
                  onClick={handleCloseMessage}
                  style={{ color: "#806e6b", cursor: "pointer" }}
                />
              </MsgContainer>
            )}
          </FormWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default ModifyElectricity;
