export const agentData = [
    {
        id: 1,
        username: "diamondtest",
        wallet: 400,
        date: "2019-05-05 03:56:52",
        note: "Agent activated on 01/07/2019",
        action: "Continue"
    },
    {
        id: 2,
        username: "diamondtest",
        wallet: 400,
        date: "2019-05-05 03:56:52",
        note: "Agent activated on 01/07/2019",
        action: "Continue"
    },
    {
        id: 3,
        username: "diamondtest",
        wallet: 400,
        date: "2019-05-05 03:56:52",
        note: "Agent activated on 01/07/2019",
        action: "Continue"
    },
    {
        id: 4,
        username: "diamondtest",
        wallet: 400,
        date: "2019-05-05 03:56:52",
        note: "Agent activated on 01/07/2019",
        action: "Continue"
    },
    {
        id: 5,
        username: "diamondtest",
        wallet: 400,
        date: "2019-05-05 03:56:52",
        note: "Agent activated on 01/07/2019",
        action: "Continue"
    },
    {
        id: 6,
        username: "diamondtest",
        wallet: 400,
        date: "2019-05-05 03:56:52",
        note: "Agent activated on 01/07/2019",
        action: "Continue"
    },
    {
        id: 7,
        username: "diamondtest",
        wallet: 400,
        date: "2019-05-05 03:56:52",
        note: "Agent activated on 01/07/2019",
        action: "Continue"
    },
]