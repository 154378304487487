import { Close, Search } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { verifyServer11 } from "../../Redux/apiCalls";
import { H2, MsgContainer } from "../transaction/pending.styles";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import "react-toastify/dist/ReactToastify.css";
import {
  Btn,
  Container,
  Form,
  FormWrapper,
  Input,
  InputContainer,
  Wrapper,
} from "./server.styles";

const Server11 = () => {
  const [refData, setRefData] = useState("");
  const [enterRefIsValid, setEnterRefIsValid] = useState(true);
  const [showMsgContainer, setShowMsgContainer] = useState(false);
  const { isFetching, verifyRef } = useSelector((state) => state.server);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setRefData(e.target.value);
  };

  const handleCloseMsg = () => {
    setShowMsgContainer(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (refData.trim() === "") {
      setEnterRefIsValid(false);
      return;
    } else {
      verifyServer11(dispatch, { ref: refData });
      if (verifyRef?.success === 1) {
        setEnterRefIsValid(true);
        setShowMsgContainer(true);
      }
      // setRefData("");
    }
  };

  console.log(verifyRef, "dmmeme");
  const result = verifyRef?.data;

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Verification &gt; Server11</H3>
            <DescP>
              Transaction / <DescSpan>Verification &gt; Server11</DescSpan>
            </DescP>
          </Desc>
          <FormWrapper>
            {showMsgContainer && (
              <>
                {!enterRefIsValid && (
                  <MsgContainer>
                    <H2>Field is required!</H2>
                    <div onClick={handleCloseMsg}>
                      <Close style={{ color: "#806e6b", cursor: "pointer" }} />
                    </div>
                  </MsgContainer>
                )}
                {result?.status === "Error" && (
                  <MsgContainer>
                    <H2>{result?.description}</H2>
                    <div onClick={handleCloseMsg}>
                      <Close style={{ color: "#806e6b", cursor: "pointer" }} />
                    </div>
                  </MsgContainer>
                )}
                {!result?.status === "Error" && (
                  <MsgContainer type="success">
                    <H2 type="success">{result?.description}</H2>
                    <div onClick={handleCloseMsg}>
                      <Close style={{ color: "#806e6b", cursor: "pointer" }} />
                    </div>
                  </MsgContainer>
                )}
              </>
            )}
            <Form onSubmit={handleSubmit}>
              <InputContainer>
                <p
                  style={{ padding: "5px", fontSize: "20px", color: "#495057" }}
                >
                  REF
                </p>
                <Input
                  type="text"
                  placeholder="Enter Server reference"
                  onChange={handleInputChange}
                  value={refData}
                />
              </InputContainer>
              <Btn type="submit">
                <Search />
                {isFetching ? " Verifying..." : "Verify"}
              </Btn>
            </Form>
          </FormWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Server11;
