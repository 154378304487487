import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { getWithdrawalData } from "../../Redux/apiCalls";
import { laptop } from "../../responsive";
import { Loading } from "../transaction/pending.styles";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import { userRequest } from "../../requestMethods";
import {
  PageNotification,
  PaginateContainer,
  PagWrapper,
} from "../Users/agent.styles";
import { formatAmount } from "../../utills/formatNumber";

const Container = styled.div`
  margin: 70px 0;
  ${laptop({ marginLeft: "250px" })};
`;
const Wrapper = styled.div`
  padding: 20px;
`;
const TableWrapper = styled.div`
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 2px 4px 10px 1pxrgba (235, 180, 180, 0.47);
  padding: 20px 30px;
`;
const Details = styled.div`
  margin: 30px 0;
`;
const Span = styled.span`
  color: #fff;
  padding: 2.6px 4.2px;
  font-size: 10.5px;
  border-radius: 5px;
`;
const Title = styled.span`
  font-size: 20px;
  color: #4a4b4c;
  margin-bottom: 15px;
  font-weight: bold;
`;
const BtnConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Button = styled.button`
  background-color: ${(prop) =>
    prop.bg === "approve" ? "#605daf" : "#f96e5b"};
  padding: 12px;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: ${(prop) =>
      prop.bg === "approve" ? "rgba(96,93,175,0.7)" : "rgba(249,110,91,0.7)"};
  }
`;

const WithdrawReq = () => {
  const [loadingRowId, setLoadingRowId] = useState(null);
  const { withdrawalData, isFetching } = useSelector((state) => state.wallet);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(withdrawalData?.last_page);
  const [currentItems, setCurrentItems] = useState(withdrawalData?.data);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  console.log(withdrawalData);

  const itemsPerPage = withdrawalData?.per_page;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      withdrawalData?.data && withdrawalData?.data.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(withdrawalData?.total / itemsPerPage));
  }, [itemOffset, withdrawalData, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % withdrawalData?.data.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    getWithdrawalData(dispatch, currentPage);
  }, [currentPage, dispatch]);

  const handleAction = async (id, action) => {
    setLoadingRowId(id);
    const url =
      action === "approve" ? "/withdrawal-approve" : "/withdrawal-reject";

    try {
      await userRequest.post(`/${url}`, { id });
      getWithdrawalData(dispatch);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRowId(null);
    }
  };

  if (isFetching) {
    return (
      <Loading>
        <CircularProgress style={{ color: "blue" }} />
      </Loading>
    );
  }
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Withdrawal List</H3>
            <DescP>
              Wallet / <DescSpan>Withdrawal List</DescSpan>
            </DescP>
          </Desc>
          <TableWrapper>
            <Title>Withdrawal Requests</Title>
            <Details>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        id
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Username
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Account Number
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Wallet
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Reference
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Bank Name
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Version
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        style={{ color: "#827fc0", fontWeight: "bold" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  {currentItems &&
                    currentItems.map((row) => (
                      <TableBody>
                        <TableRow
                          key={row?.id}
                          style={{ backgroundColor: "#f3f2f7" }}
                        >
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.id}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.user_name}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.account_number}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            ₦{formatAmount(row.amount)}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            <Span
                              style={{
                                backgroundColor: `${
                                  row.status === 1
                                    ? "#5dd099"
                                    : row.status === 0
                                    ? "#f96e5b"
                                    : "#33cdff"
                                }`,
                              }}
                            >
                              {row.status === 0
                                ? "pending"
                                : row.status === 1
                                ? "completed"
                                : "rejected"}
                            </Span>
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            ₦{formatAmount(row.wallet)}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.ref}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.bank}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.version}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.created_at}
                          </TableCell>
                          <TableCell style={{ color: "#8887a9" }}>
                            {row.status === 0 && (
                              <BtnConatiner>
                                <Button
                                  bg="approve"
                                  onClick={() =>
                                    handleAction(row.id, "approve")
                                  }
                                  disabled={loadingRowId === row.id}
                                >
                                  {loadingRowId === row.id ? (
                                    <CircularProgress
                                      size={24}
                                      style={{ color: "white" }}
                                    />
                                  ) : (
                                    "Approve"
                                  )}
                                </Button>
                                <Button
                                  onClick={() => handleAction(row.id, "reject")}
                                  disabled={loadingRowId === row.id}
                                >
                                  {loadingRowId === row.id ? (
                                    <CircularProgress
                                      size={24}
                                      style={{ color: "white" }}
                                    />
                                  ) : (
                                    "Reject"
                                  )}
                                </Button>
                              </BtnConatiner>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
              <PagWrapper>
                <PageNotification>
                  Showing {withdrawalData?.from || "0"} to{" "}
                  {withdrawalData?.to || "0"} of {withdrawalData?.total || "0"}{" "}
                  entries
                </PageNotification>
                <PaginateContainer
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageLinkClassName="pageNum"
                  previousLinkClassName="pageNum"
                  nextLinkClassName="pageNum"
                  forcePage={currentPage - 1}
                />
              </PagWrapper>
            </Details>
          </TableWrapper>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default WithdrawReq;
