import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/mcd_logo.png";
import { login, logout } from "../../Redux/apiCalls";
import {
  Button,
  Container,
  Desc,
  ErrorWrapper,
  ErrSpan,
  ErrTitle,
  Form,
  Fotter,
  H3,
  Img,
  Input,
  Remember,
  RemInput,
  Span,
  Wrapper,
} from "./login.styles";

const Login = () => {
  const d = new Date();
  let year = d.getFullYear();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { currentUser, isFetching, error, message } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();

  console.log(currentUser, "curewn");

  const handleClick = (e) => {
    e.preventDefault();
    login(dispatch, { email, password });
  };



  useEffect(() => {
    if (currentUser) {
      console.log("User is logged in, navigating to home.");
      navigate("/");
    } else {
      dispatch(logout());
      navigate("/login");
      console.log("User is not logged in, staying on login page.");
    }
  }, [currentUser, dispatch, navigate]);

  useEffect(() => {
    const persistedData = localStorage.getItem("persist:root");
    if (!persistedData) {
      console.log("No persisted data found in localStorage.");
      return;
    }

    const user = JSON.parse(persistedData)?.user;
    const token = user?.currentUser?.token;

    console.log("Token from localStorage:", token);

    if (!token) {
      console.log("Token not found in localStorage.");
      dispatch(logout());
      navigate("/login");
      return;
    }

    const tokenParts = token?.split("|");
    if (tokenParts?.length === 2) {
      const expirationTimestamp = parseInt(tokenParts[0], 10);
      const expirationDate = new Date(expirationTimestamp * 1000);
      if (expirationDate < new Date()) {
        console.log("Token has expired.");
        dispatch(logout());
        navigate("/login");
      } else {
        console.log("Token is still valid.");
        navigate("/");
      }
    } else {
      console.log("Invalid token format.");
      dispatch(logout());
      navigate("/login");
    }
  }, [dispatch, navigate]);

  return (
    <Container>
      <Wrapper>
        <Desc>
          <Img src={logo} alt="logo" />
          <H3>Welcome to Mega Cheap Data</H3>
        </Desc>
        <Form onSubmit={handleClick}>
          {error && (
            <ErrorWrapper>
              <ErrTitle>These credentails do not match our records!</ErrTitle>
              <ErrSpan>Change a few things up and try submitting again</ErrSpan>
            </ErrorWrapper>
          )}

          {message && <ErrTitle>You have successfully logout</ErrTitle>}

          <Input
            name="email"
            type="email"
            placeholder="admin..@abc.com"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            name="password"
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Remember>
            <RemInput type="checkbox" name="check" id="check" />
            <Span>Remember Me</Span>
          </Remember>
          <Button type="submit">
            {isFetching ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              "Login"
            )}
          </Button>
        </Form>
        <Fotter>Powered by 5Star Company &copy; {year}</Fotter>
      </Wrapper>
    </Container>
  );
};

export default Login;
